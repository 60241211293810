/* global window, document, liveagent, _laq, utag, utag_data, utagDebug, moment, ProactiveChat */
/* eslint-disable no-console, no-global-assign */

const MONDAY = 1;
const FRIDAY = 5;

window.ProactiveChat = {};

class Button {
  constructor(buttonId, el, toggleFn) {
    this.el = el;
    this.buttonId = buttonId;
    this.toggle = toggleFn;
    this.available = false;

    const onAvailableCallbacks = [];
    const onAcceptedCallbacks = [];
    const onUnavailableCallbacks = [];

    this.bindOnAvailable = callback => onAvailableCallbacks.push(callback);
    this.bindOnAccepted = callback => onAcceptedCallbacks.push(callback);
    this.bindOnUnavailable = callback => onUnavailableCallbacks.push(callback);

    this.bindOnAvailable(() => {
      if (typeof this.toggle !== 'undefined') {
        this.toggle(true);
      }

      this.available = true;
    });

    this.bindOnAccepted(() => {
      if (typeof this.toggle !== 'undefined') {
        this.toggle(false);
      }
    });

    this.bindOnUnavailable(() => {
      if (typeof this.toggle !== 'undefined') {
        this.toggle(false);
      }

      this.available = false;
    });

    this.init = () => {
      liveagent.addButtonEventHandler(this.buttonId, event => {
        if (event === liveagent.BUTTON_EVENT.BUTTON_AVAILABLE) {
          onAvailableCallbacks.forEach(callback => callback());
        } else if (event === liveagent.BUTTON_EVENT.BUTTON_ACCEPTED) {
          onAcceptedCallbacks.forEach(callback => callback());
        } else if (
          event === liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE ||
          event === liveagent.BUTTON_EVENT.BUTTON_REJECTED
        ) {
          onUnavailableCallbacks.forEach(callback => callback());
        }
      });
    };
  }
}

ProactiveChat.isWithinBusinessHours = hours => {
  moment.tz.add(
    'America/Toronto|EST EDT EWT EPT|50 40 40 40|01010101010101010101010101010101010101010101012301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-25TR0 1in0 11Wu 1nzu 1fD0 WJ0 1wr0 Nb0 1Ap0 On0 1zd0 On0 1wp0 TX0 1tB0 TX0 1tB0 TX0 1tB0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 4kM0 8x40 iv0 1o10 11z0 1nX0 11z0 1o10 11z0 1o10 1qL0 11D0 1nX0 11B0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|65e5'
  );
  const now = moment.parseZone(
    moment()
      .tz('America/Toronto')
      .format()
  );
  const weekday = now.day();
  let opening = hours.opening.split(':').map(value => parseInt(value, 10));
  let closing = hours.closing.split(':').map(value => parseInt(value, 10));
  opening = now.clone().set({
    hour: opening[0],
    minute: opening[1],
  });
  closing = now.clone().set({
    hour: closing[0],
    minute: closing[1],
  });

  return (
    weekday >= MONDAY && weekday <= FRIDAY && now.isBetween(opening, closing)
  );
};

ProactiveChat.loadDeployment = (host, apiVersion, callback) => {
  const script = document.createElement('script');
  script.src = `https://c.${host}/content/g/js/${apiVersion}/deployment.js`;
  script.onload = () => {
    callback();
  };
  script.onerror = e => {
    callback(e);
  };

  document.head.appendChild(script);
};

ProactiveChat.track = data => {
  if (typeof utag !== 'undefined') {
    utag.link(JSON.parse(JSON.stringify(data)), () => {
      if (typeof utagDebug !== 'undefined' && utagDebug) {
        console.log(data);
      }
      Object.keys(data).forEach(prop => delete utag_data[prop]);
    });
  }
};

ProactiveChat.createButton = (buttonId, text) => {
  const button = document.createElement('a');
  button.id = `liveagent_button_online_${buttonId}`;
  button.style.display = 'none';
  button.classList.add('chat-button');
  button.text = text;
  button.onclick = () => {
    liveagent.startChat(buttonId);
    ProactiveChat.track({
      site_interaction: 'yes',
      site_interaction_link: (button.text.trim().length > 0)?button.text:'chat',
      proactive_chat_click: 'yes',
      chat_online_click: 'yes',
    });
  };

  document.body.appendChild(button);

  if (typeof _laq === 'undefined') {
    // eslint-disable-next-line
    window._laq = [];
  }

  _laq.push(() => {
    liveagent.showWhenOnline(buttonId, button);
  });

  return new Button(buttonId, button);
};

ProactiveChat.createInvite = (buttonId, strings) => {
  const className = 'chat-invite';
  const invite = document.createElement('div');
  invite.id = `liveagent_invite_button_${buttonId}`;
  invite.setAttribute('aria-hidden', 'true');
  invite.classList.add(className);
  invite.style.display = 'none';
  invite.innerHTML = `
    <div class="${className}__text">
      ${strings.text}
    </div>
  `;

  const reject = document.createElement('span');
  reject.classList.add(`${className}__reject`);
  reject.setAttribute('role', 'button');
  reject.innerText = strings.reject;
  reject.onclick = () => {
    liveagent.rejectChat(buttonId);
    ProactiveChat.track({
      site_interaction: 'yes',
      site_interaction_link: reject.innerText,
      proactive_chat_no_thanks_click: 'yes',
    });
  };
  invite.appendChild(reject);

  const accept = document.createElement('span');
  accept.classList.add(`${className}__accept`);
  accept.setAttribute('role', 'button');
  accept.innerText = strings.accept;
  accept.onclick = () => {
    liveagent.startChat(buttonId);
    ProactiveChat.track({
      site_interaction: 'yes',
      site_interaction_link: accept.innerText,
      proactive_chat_chat_now_click: 'yes',
    });
  };
  invite.appendChild(accept);

  const toggle = on => {
    if (on) {
      invite.setAttribute('aria-hidden', 'false');
      invite.style.display = '';
      // eslint-disable-next-line no-unused-expressions
      window.getComputedStyle(invite).opacity;
      invite.classList.add(`${className}--visible`);
    } else {
      invite.setAttribute('aria-hidden', 'true');
      invite.classList.remove(`${className}--visible`);
      // eslint-disable-next-line no-unused-expressions
      window.getComputedStyle(invite).opacity;
      invite.ontransitionend = e => {
        if (e.propertyName === 'transform') {
          invite.style.display = 'none';
        }
      };
    }
  };

  document.body.appendChild(invite);

  return new Button(buttonId, invite, toggle);
};

ProactiveChat.init = config => {
  if (
    config.api.buttonId && (
      (typeof config.force !== 'undefined' && config.force) ||
      ProactiveChat.isWithinBusinessHours(config.ui.hours)
    )
  ) {
    ProactiveChat.button = ProactiveChat.createButton(
      config.api.buttonId,
      config.ui.strings.buttonText
    );

    if (config.api.inviteId) {
      ProactiveChat.invite = ProactiveChat.createInvite(config.api.inviteId, {
        text: config.ui.strings.inviteText,
        accept: config.ui.strings.inviteAccept,
        reject: config.ui.strings.inviteReject,
      });
    }

    ProactiveChat.loadDeployment(
      config.api.host,
      config.api.apiVersion,
      error => {
        if (typeof error !== 'undefined') {
          console.error(error);
          return;
        }

        liveagent.setChatWindowHeight(config.ui.popupHeight);
        liveagent.init(
          `https://d.${config.api.host}/chat`,
          config.api.deploymentId,
          config.api.deploymentOrg
        );

        ProactiveChat.button.init();
        if (ProactiveChat.invite) {
          ProactiveChat.invite.init();
        }
      }
    );
  }
};
